import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Tryouts() {
  return (
    <div className="content">
      <Helmet>
        <title>Girl's Tryouts</title>
        <meta
          name="description"
          content="Get registered for our girl's youth volleyball club season."
        />
      </Helmet>
      <h1>2024 Girl's Tryouts</h1>
      <h2>
        Projected Dates: November 10th (14 and under) and November 30th (15-18u)
      </h2>
      <h3>
        All open gyms and tryouts will take place at Tucson High School unless
        marked otherwise
      </h3>
      {/* <div style={{ textAlign: "center" }}>
        <h3>U12-U14 (born on or after July 1, 2009):</h3>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>October 27th: Open House from 7:00 PM to 9:00 PM</li>
          <li>November 4th: Open House from 6:15 PM to 8:15 PM</li>
          <li>
            <strong>November 5th: Tryouts from 4:00 PM to 6:00 PM</strong>
          </li>
        </ul>
        <a
          href="https://dashboard.sidewinderva.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register Here
        </a>
        <h3>15u-17u (born before July 1, 2009)</h3>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>
            <strong>MOVED!</strong> November 14th: Open House from 6:00 PM to
            8:00 PM <strong>AT ASDB</strong>
          </li>
          <li>
            Arizona State School for the Deaf and Blind. 1200 W Speedway Blvd.
          </li>
          <li></li>
          <li>November 18th: Open House from 6:15 PM to 8:15 PM</li>
          <li>
            <strong>November 25th: Tryouts from 6:30 PM to 8:30 PM</strong>
          </li>
        </ul>
        <a
          href="https://dashboard.sidewinderva.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register Here
        </a>
      </div> */}
      <h1>FAQs</h1>
      <h4>What is the process to tryout?</h4>
      <p>
        <Link to="/tryoutprocess">
          Follow this guide to sign up and join us!
        </Link>
      </p>
      <h4>What Age Should I Tryout For?</h4>
      <p>
        When you register your athlete for tryouts using the steps linked above,
        their "club age" is now listed directly on the registration portal.
      </p>
      <h4>How does the club season work?</h4>
      <p>
        Each Sidewinder team is made up of 9-13 kids from all around Tucson.
        Teams are broken up according to their age and their may be more than
        one team per age group. Usually one team in each age group will be a
        "travel" team and the rest will be "region" teams.
      </p>
      <p>
        All Sidewinder teams play in the Arizona Region of USA Volleyball.
        Arizona Region tournaments are half-day tournaments, typically on
        Saturday, where our teams are matched up against other club teams from
        around Arizona according to their skill level. Tournaments are typically
        hosted in Phoenix. Each team practices two times per week at night at
        one of our partner schools. We currently use Tucson High School and
        Arizona School for the Deaf and Blind for practices. Both are located
        off of Speedway and I-10.
      </p>
      <h4>What is a region team?</h4>
      <p>
        Region teams have 6 half day tournaments (starting at either 8am or 3pm)
        and 1 full day tournament. Region teams will also participate in the
        Cactus Classic over MLK weekend. Teams will start practicing in January
        and end with the Region Championship tournament in May.{" "}
      </p>
      <h4>What is a travel team?</h4>
      <p>
        Travel teams have all of the same tournaments as the region teams but
        also compete nationally. Every travel team will compete in the national
        championship tournament in June/July. Certain travel teams may also
        attend out-of-state "qualifier" tournaments to attempt to qualify for a
        higher division at the national tournament.
      </p>
      <h4>Costs</h4>
      <p>
        Tryouts are $25. After making a team, dues will be structured as shown
        below:
      </p>
      <p>
        <strong>Region Teams</strong>
      </p>
      <p>
        Region teams attend 6 region tournaments in Phoenix against other clubs
        in the Arizona Region. Girl's region teams will also participate in the
        Cactus Classic (Tucson Convention Center) over MLK weekend against teams
        from around the country. The region team season ends with the region
        championships in late April/early May.
      </p>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Monthly Plan</td>
            <td>Pay In Full Plan</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts</td>
            <td>$400</td>
            <td>$400</td>
          </tr>
          <tr>
            <td>Dec 5th, 2022</td>
            <td>$0</td>
            <td>$1450</td>
          </tr>
          <tr>
            <td>Jan 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Feb 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Mar 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Apr 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$2000</td>
            <td>$1850</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Travel Teams</strong>
        <p>
          In addition to the 6 region tournaments and Cactus Classic (Tucson,
          MLK Weekend), girl's travel teams also go to a national level travel
          tournament (April) and the AAU Girls Junior National Championship
          (June).
        </p>
      </p>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Monthly Plan</td>
            <td>Pay In Full Plan</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts</td>
            <td>$500</td>
            <td>$500</td>
          </tr>
          <tr>
            <td>Dec 5th, 2022</td>
            <td>$0</td>
            <td>$2350</td>
          </tr>
          <tr>
            <td>Jan 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Feb 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Mar 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Apr 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>May 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$3000</td>
            <td>$2850</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default Tryouts;
